import React from 'react';
import { ExternalEventLink } from '../../../form-elements/Link';
import LinkIcon from './components/LinkIcon';
import NOALink from './components/NOALink';
import LinkSelector from '../../../Header/LinkSelector';
import { useSelector } from 'react-redux';
import { linksFooterSelector } from '../../../../../modules/selectors/standort/theme';

function NOAFooter() {
    const themeLinksFooter = useSelector(linksFooterSelector);
    return (
        <div id="custom-theme-noa--footer">
            <div className="footer uk-section-default uk-section uk-section-xsmall">
                <div className="uk-container">
                    <div
                        className="uk-margin-remove-top uk-margin-remove-bottom tm-grid-expand uk-grid-divider uk-grid-margin uk-grid"
                        data-uk-grid=""
                    >
                        <div className="uk-width-1-2@s uk-width-1-4@m">
                            <div className="uk-panel uk-text-primary uk-margin uk-margin-remove-bottom">
                                <p>Kontakt</p>
                            </div>
                            <div className="uk-panel uk-margin">
                                <p>
                                    <span className="sitemap__phonetext">Telefonische Anfrage (alle Center):</span>
                                </p>
                                <h3>
                                    <ExternalEventLink
                                        className="sitemap__link sitemap__phonelink"
                                        to="tel:+49481603177"
                                        action="Klick-Telefon"
                                        category="Anfrage"
                                        label=" +49 481 603-177"
                                    >
                                        +49 481 603-177
                                    </ExternalEventLink>
                                    <br />
                                    <ExternalEventLink
                                        className="sitemap__link sitemap__phonelink"
                                        to="tel:+4940725707177"
                                        action="Klick-Telefon"
                                        category="Anfrage"
                                        label="+49 40 725707-177"
                                    >
                                        +49 40 725707-177
                                    </ExternalEventLink>
                                </h3>
                                <p>
                                    <NOALink to="/ansprechpartner">Ansprechpartner finden</NOALink>
                                </p>
                                <p />
                            </div>
                        </div>

                        <div className="uk-width-1-2@s uk-width-1-4@m">
                            <div className="uk-panel uk-text-primary uk-margin">
                                <p>Wunschfahrzeug</p>
                            </div>
                            <div className="uk-panel uk-margin">
                                <p>Mehr als 1.400 sofort verfügbare Fahrzeuge</p>
                            </div>
                            <div className="uk-margin uk-text-left">
                                <div
                                    className="uk-flex-middle uk-grid-column-medium uk-grid-row-small uk-child-width-1-1 uk-grid uk-grid-stack"
                                    data-uk-grid=""
                                >
                                    <div className="el-item">
                                        <NOALink
                                            className="el-content"
                                            fullWidth
                                            to="https://www.mercedes-benz-nord-ostsee.de/de/desktop/home.html"
                                        >
                                            <LinkIcon />
                                            <span className="uk-text-middle"> Mercedes-Benz</span>
                                        </NOALink>
                                    </div>

                                    <div className="el-item uk-grid-margin">
                                        <NOALink className="el-content" fullWidth to="/amg/">
                                            <LinkIcon />
                                            <span className="uk-text-middle"> AMG</span>
                                        </NOALink>
                                    </div>

                                    <div className="el-item uk-grid-margin">
                                        <NOALink className="el-content" fullWidth to="https://astonmartin-hamburg.com/">
                                            <LinkIcon />
                                            <span className="uk-text-middle"> Aston Martin</span>
                                        </NOALink>
                                    </div>

                                    <div className="el-item uk-grid-margin">
                                        <NOALink className="el-content" fullWidth to="/smart-2/">
                                            <LinkIcon />
                                            <span className="uk-text-middle"> smart</span>
                                        </NOALink>
                                    </div>

                                    <div className="el-item uk-grid-margin">
                                        <NOALink className="el-content" fullWidth to="https://hyundai.nord-ostsee-automobile.de/">
                                            <LinkIcon />
                                            <span className="uk-text-middle"> Hyundai</span>
                                        </NOALink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="uk-width-1-2@s uk-width-1-4@m">
                            <div className="uk-panel uk-text-primary uk-margin">
                                <p>Werkstatt &amp; Services</p>
                            </div>
                            <div className="uk-margin uk-text-left">
                                <div
                                    className="uk-flex-middle uk-grid-column-medium uk-grid-row-small uk-child-width-1-1 uk-grid uk-grid-stack"
                                    data-uk-grid=""
                                >
                                    <div className="el-item">
                                        <NOALink className="el-content" fullWidth to="/werkstatt/">
                                            <LinkIcon />
                                            <span className="uk-text-middle"> Top Aktionen &amp; Angebote</span>
                                        </NOALink>
                                    </div>

                                    <div className="el-item uk-grid-margin">
                                        <NOALink
                                            className="el-content"
                                            fullWidth
                                            to="https://booking.digitalesautohaus.de/#/bookings/2d7cfede-71ea-4127-9429-bf2e377d3212"
                                        >
                                            <LinkIcon />
                                            <span className="uk-text-middle"> Servicetermin vereinbaren</span>
                                        </NOALink>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-panel uk-text-primary uk-margin">
                                <p>Center in Ihrer Nähe</p>
                            </div>
                            <div className="uk-margin uk-text-left">
                                <NOALink className="el-content" fullWidth to="/center/">
                                    <LinkIcon />
                                    <span className="uk-text-middle"> Center-Karte</span>
                                </NOALink>
                            </div>
                        </div>

                        <div className="uk-width-1-2@s uk-width-1-4@m">
                            <div className="uk-panel uk-text-primary uk-margin">
                                <p>Unternehmen</p>
                            </div>
                            <div className="uk-margin uk-text-left">
                                <div
                                    className="uk-flex-middle uk-grid-column-medium uk-grid-row-small uk-child-width-1-1 uk-grid uk-grid-stack"
                                    data-uk-grid=""
                                >
                                    <div className="el-item">
                                        <NOALink className="el-content" fullWidth to="/">
                                            <LinkIcon />
                                            <span className="uk-text-middle"> News</span>
                                        </NOALink>
                                    </div>

                                    <div className="el-item uk-grid-margin">
                                        <NOALink className="el-content" fullWidth to="/karriere/stellenangebote-und-ausbildungsplaetze/">
                                            <LinkIcon />
                                            <span className="uk-text-middle"> Ausbildung- und Jobangebote</span>
                                        </NOALink>
                                    </div>
                                </div>
                            </div>

                            <div className="uk-margin uk-text-left">
                                <div
                                    className="uk-flex-middle uk-grid-column-medium uk-grid-row-small uk-child-width-1-1 uk-grid uk-grid-stack"
                                    data-uk-grid=""
                                >
                                    <div className="el-item">
                                        <LinkSelector
                                            to={themeLinksFooter.impressum.to}
                                            type={themeLinksFooter.impressum.type}
                                            label={themeLinksFooter.impressum.label}
                                        />
                                    </div>

                                    <div className="el-item uk-grid-margin">
                                        <LinkSelector
                                            to={themeLinksFooter.datenschutz.to}
                                            type={themeLinksFooter.datenschutz.type}
                                            label={themeLinksFooter.datenschutz.label}
                                        />
                                    </div>

                                    <div className="el-item uk-grid-margin">
                                        <LinkSelector
                                            to={themeLinksFooter.agb.to}
                                            type={themeLinksFooter.agb.type}
                                            label={themeLinksFooter.agb.label}
                                        />
                                    </div>
                                    <div className="el-item uk-grid-margin">
                                        <LinkSelector
                                            to={themeLinksFooter.gutscheine.to}
                                            type={themeLinksFooter.gutscheine.type}
                                            label={themeLinksFooter.gutscheine.label}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tm-grid-expand uk-grid-margin uk-grid" data-uk-grid="">
                        <div className="uk-width-3-4@m">
                            <div className="uk-panel uk-text-meta uk-margin">
                                <div className="sitemap__col sitemap__col--2">
                                    <p className="sitemap__text">
                                    Nord-Ostsee Automobile SE & Co. KG ist ein Autorisierter AMG, Mercedes-Benz, Aston Martin, Hymer, smart, Hyundai und Iveco Vertragspartner für den Verkauf und Service von Pkw, 
                                    Transportern und Trucks. Unser Team besteht aus mehr als 1.800 Mitarbeitenden in 34 Centern in Hamburg, Schleswig-Holstein, Brandenburg und der Region Hannover.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="uk-width-1-4@m">
                            <div className="uk-panel uk-margin">
                                <p>Folgen Sie Nord-Ostsee Automobile</p>
                            </div>
                            <div className="uk-margin">
                                <div className="uk-child-width-auto uk-grid-small uk-flex-inline uk-grid" data-uk-grid="">
                                    <div>
                                        <NOALink
                                            className="el-link uk-icon-button uk-icon"
                                            to="https://www.facebook.com/nordostseeautomobile/"
                                        >
                                            <span data-uk-icon="icon: facebook" />
                                        </NOALink>
                                    </div>
                                    <div>
                                        <NOALink
                                            className="el-link uk-icon-button uk-icon"
                                            to="https://www.instagram.com/nord_ostsee_automobile/"
                                        >
                                            <span data-uk-icon="icon: instagram" />
                                        </NOALink>
                                    </div>
                                    <div>
                                        <NOALink
                                            className="el-link uk-icon-button uk-icon"
                                            to="https://www.facebook.com/hyundainordostseeautomobile/"
                                        >
                                            <span data-uk-icon="icon: facebook" />
                                        </NOALink>
                                    </div>
                                    <div>
                                        <NOALink
                                            className="el-link uk-icon-button uk-icon"
                                            to="https://www.instagram.com/hyundai.nord_ostsee_automobile/"
                                        >
                                            <span data-uk-icon="icon: instagram" />
                                        </NOALink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NOAFooter;
